*,
*::before,
*::after {
  box-sizing: border-box;
}

.header1-text {
  font-size: 25px;
  color: #90cdf9;
  font-weight: 500;
}

.header2-text {
  font-size: 14px;
  font-weight: 500;
}

.header3-text {
  font-size: 12px;
  font-weight: 500;
}

.base-text {
  font-size: 12px;
}

.help-text {
  font-size: 12px;
  color: #999;
}

.disabled-text {
  font-size: 12px;
  color: #ccc;
}

.scheduler {
  /* margin: 20px auto;
  border-spacing: 0;
  border: 1px solid red;
  overflow: hidden; */
  margin: 20px auto;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid red; */
  overflow-x: auto;
  overflow: hidden;
  display: block;

}

.scheduler td {
  padding: 0px;
}

.expander-space {
  overflow: hidden;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 0.9em;
  vertical-align: middle;
  margin-top: -1%;
}

.resource-view {
  border: 1px solid #e9e9e9;
  overflow: hidden;
}

.scheduler-view {
  border: 1px solid #e9e9e9;
  margin: 0 0 0 -1px;
  padding: 0;
  overflow-x: auto;
  width: 58%;
}

.scheduler-content {
  position: relative;
  z-index: 2;
}

.scheduler-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

table.resource-table,
table.scheduler-bg-table,
table.scheduler-table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  text-align: center;
}

table.scheduler-table {
  border: 1px solid #e9e9e9;
}

table.scheduler-content-table {
  margin: 0;
  padding: 0;
  border: 0 solid #e9e9e9;
  border-spacing: 0;
}

table.resource-table tr,
table.scheduler-bg-table tr,
table.scheduler-table tr {
  border-bottom: 1px solid #e9e9e9;
}

table.resource-table th,
table.scheduler-table th,
table.resource-table td,
table.scheduler-bg-table td,
table.scheduler-table td {
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

table.scheduler-bg-table th {
  border-right: 1px solid #e9e9e9;
}

table.resource-table tr th:last-child,
table.scheduler-table tr th:last-child,
table.resource-table tr td:last-child,
table.scheduler-table tr td:last-child {
  border-right: 0;
}

table.scheduler-table tr:last-child td {
  border-bottom: 0;
}

.resource-table thead:first-child tr {
  background-color: #EFF2F5;
}

.scheduler-bg-table thead:first-child tr {
  background-color: #EFF2F5;
}

/* .scheduler-content-table tbody tr{
  height: 40px !important;
}

.scheduler-content-table tbody tr td{
  height: 100% !important;
}

.scheduler-content-table tbody tr td .event-container{
  height: 100% !important;
} */


.timeline-event {
  position: absolute;
  cursor: pointer;
}

.day-event {
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.day-event-container {
  text-align: left;
  padding: 5px 5px 0;
}

.round-all {
  border-radius: 14px;
}

.round-head {
  border-radius: 14px 0px 0px 14px;
}

.round-tail {
  border-radius: 0px 14px 14px 0px;
}

.round-none {
  border-radius: 0px;
}

.event-container {
  position: relative;
}

.event-item {
  margin: 1px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  padding-right: 20px !important;
  margin-top: 6px;
}

.overflow-text {
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  padding-right: 5px !important;
}

.status-dot {
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.ant-radio-button-wrapper-checked {
  background-color: #108ee9;
  color: #ffffff;
}

.icon-nav:hover {
  color: #1e90ff !important;
  box-shadow: 0 0 0px !important;
  cursor: pointer;
}

.add-more-popover-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.popover-calendar {
  width: 300px;
}

.popover-calendar .ant-calendar {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0) !important;
}

.event-resizer {
  position: absolute;
  z-index: 4;
  display: block;
  width: 7px;
  top: -1px;
  bottom: -1px;
}

.event-start-resizer {
  cursor: w-resize;
  left: -1px;
}

.event-end-resizer {
  cursor: e-resize;
  right: -1px;
}

.selected-area {
  position: absolute;
}

.slot-cell {
  padding-left: 4px;
  padding-right: 4px;
}

.slot-text {
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
}

.txt-btn-dis {
  border: none;
  background-color: transparent;
}

/* @media (min-width: 1400px) {
  .scheduler-view {
    width: auto
  }
} */



.ant-radio-group-solid {
  margin-right: 4px !important;
}

.ant-radio-group .ant-radio-group-outline .ant-radio-group-small .ant-picker-calendar-mode-switch {
  display: none !important;
}

.ant-radio-group .ant-radio-group-outline .ant-radio-group-small .ant-picker-calendar-mode-switch label {
  display: none;
}



@media(max-width:2000px) {
  .scheduler-view {
    width: 93%;
  }
}


@media(max-width:1950px) {
  .scheduler-view {
    width: 90%;
  }
}


@media(max-width:1900px) {
  .scheduler-view {
    width: 87%;
  }
}

@media(max-width:1850px) {
  .scheduler-view {
    width: 85%;
  }

  /* .resource-view{
    width: 130px;
  } */
}

@media(max-width:1800px) {
  .scheduler-view {
    width: 82%;
  }
}

@media(max-width:1750px) {
  .scheduler-view {
    width: 78%;
  }
}

@media(max-width:1700px) {
  .scheduler-view {
    width: 76%;
  }
}

@media(max-width:1650px) {
  .scheduler-view {
    width: 72%;
  }
}

@media(max-width:1600px) {
  .scheduler-view {
    width: 68%;
  }
}

@media(max-width:1550px) {
  .scheduler-view {
    width: 65%;
  }
}

@media (max-width:1500px) {
  .scheduler-view {
    width: 65%;
  }
}

@media (max-width:1450px) {
  .scheduler-view {
    width: 60%;
  }
}

@media (max-width:1440px) {
  .scheduler-view {
    width: 58%;
  }
}

@media(max-width:1380px) {
  .scheduler-view {
    width: 55%;
  }
}

@media(max-width:1350px) {
  .scheduler-view {
    width: 51%;
  }

}

@media(max-width:1300px) {
  .scheduler-view {
    width: 49%;
  }

}

@media(max-width:1250px){
  .scheduler-view{
    width: 45%;
  }
}

@media(max-width:1200px) {
  .scheduler-view {
    width: 37%;
  }

}

@media(max-width:1100px){
  .scheduler-view{
    width: 34%;
  }
}

@media(max-width:1050px){
  .scheduler-view{
    width: 33%;
  }
}

@media(max-width:1000px){
  .scheduler-view{
    width: 17%;
  }
}

@media(max-width:900px) {
  .scheduler-view {
    width: 34%;
  }
}

@media(max-width:850px){
  .scheduler-view{
    width: 30%;
  }
}

@media(max-width:800px) {
  .scheduler-view {
    width: 27%;
  }
}

@media(max-width:750px) {
  .scheduler-view {
    width: 24%;
  }
}

@media(max-width:700px) {
  .scheduler-view {
    width: 20%;
  }
}


@media(max-width:650px) {
  .scheduler-view {
    width: 280px;
  }
}

@media(max-width:600px) {
  .scheduler-view {
    width: 220px;
  }

  /* .resource-view {
    width: 150px;
  } */
}

@media(max-width:550px) {
  .resource-view {
    width: 150px;
  }

  .scheduler-view {
    width: 240px;
  }
}


@media(min-width:2001px) {
  .scheduler-view {
    width: auto;
  }
}

tr td{
  height: 40px !important;
}