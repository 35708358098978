@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import "react-big-calendar/lib/css/react-big-calendar.css"; */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: "Poppins", sans-serif;
}


body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.hideshow {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
}

/* Visible components */
.show {
  opacity: 1;
  height: auto;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
}

.table-row-dark {
  background-color: #fbfbfb;
}

.ant-table-wrapper .ant-table-pagination-right {
  justify-content: center !important;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #2BA04E !important;
}

.ant-pagination:not(.ant-pagination-disabled) .ant-pagination-item:focus-visible {
  outline: none !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #2BA04E !important;
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: #2BA04E !important;
}

.signin-logo h1,
.signin-logo p {
  font-family: TTAlientzTrialSerif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-modal .ant-modal-close {
  background: black !important;
  border-radius: 40px !important;
  color: white !important;
}

.ant-modal .ant-modal-close-x {
  font-size: 14px !important;
}

.ant-image .ant-image-mask:hover {
  border-radius: 37px !important;
}

.ant-image .ant-image-mask {
  border-radius: 37px !important;
}

.ant-input-affix-wrapper>input.ant-input {
  background-color: #eff2f5 !important;
}

.ant-input-affix-wrapper {
  border: none !important;
  /* border: 1px solid #F2F2F4 !important; */
  outline: none !important;
}


.ant-input-affix-wrapper:focus-within {
  border: none !important;
  box-shadow: none !important;
}

.ant-select .ant-select-selector {
  border: none !important;
}

.ant-select-focused .ant-select .ant-select-selector {
  box-shadow: none !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1vy9e9v).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
  outline: 0;
}

.ant-select-single .ant-select-selector {
  color: #9B9CA9 !important;
}

.ant-picker-focused.ant-picker {
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.ant-picker-focused.ant-picker {
  border-color: none !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: none !important;
}

.yellow-light:hover {
  background-color: #eeb600;
}

.yellow-bg {
  background-color: #eeb600;
}

.black-bg {
  background-color: #2F3032;
}

.orange-bg {
  background-color: #ff9900;
}

.green-bg {
  background-color: #2B9943;
}

.gry-bg {
  background-color: #f5f5f5;
}

.ant-checkbox-group {
  display: flex !important;
  flex-direction: column !important;
  row-gap: 1rem !important;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: white !important;
  border-color: transparent !important;
}

.w-27 {
  width: 8vw;
}

.border-heading {
  border-bottom: 0.5px solid gray;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #eeb600 !important;
}

.ant-tabs .ant-tabs-tab {
  color: #8a94a6 !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black !important;
}

.ant-input:hover {
  border: none !important;
}

.ant-input:focus-within {
  box-shadow: none !important;
  outline: none !important;
}

.ant-select-selector {
  background-color: #eff2f5 !important
}


:where(.css-dev-only-do-not-override-gzal6t).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #EEB600 !important;
  border: none !important;
}

/* styles.css or your custom CSS file */
/* Style the scrollbar */
::-webkit-scrollbar {
  width: 12px;
  /* Width of vertical scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #ff9900d7;
  /* Color of the thumb */
  border-radius: 6px;
  /* Rounded corners */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color of the track */
}

/* For horizontal scrollbar (optional) */
::-webkit-scrollbar:horizontal {
  height: 12px;
  /* Height of horizontal scrollbar */
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #ff9900;
  /* Color of the thumb for horizontal scrollbar */
}

:where(.css-dev-only-do-not-override-gzal6t).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  height: 1vh;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #EEB600 !important;
  border: none !important;
}

/* styles.css or your custom CSS file */
/* Style the scrollbar */
::-webkit-scrollbar {
  width: 8px;

}

::-webkit-scrollbar-thumb {
  background-color: #ff9900c2;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* For horizontal scrollbar (optional) */
::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #ff9900c2;
  /* Color of the thumb for horizontal scrollbar */
}

a:hover {
  color: inherit;
}

a:hover {
  color: inherit;
}

/* antd select field placeholder */
:where(.css-dev-only-do-not-override-1kej392).ant-select-single.ant-select-show-arrow .ant-select-selection-item,
:where(.css-dev-only-do-not-override-1kej392).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: black !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-k064qb).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  box-shadow: none !important;
}

.ant-select .ant-select-selector {
  outline: none !important;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-input-affix-wrapper:focus-within {
  outline: none !important;
}

.outline-none {
  outline: none !important;
  outline-offset: none !important
}

.ant-pagination .ant-pagination-item-active {
  border-color: #EEB600 !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #EEB600 !important;
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: #EEB600 !important;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-input-affix-wrapper:focus {
  box-shadow: none !important;
}

.py-4 {
  padding-bottom: 2px !important;
}

.ant-picker {
  background: #EFF2F5 !important;
}

form input {
  outline: none !important;
  /* border: none !important; */
}

.ant-table-thead .ant-table-cell {
  background: #EFF2F5 !important;
  color: #BBBBBB !important;
  
}



.focus\:ring-4:focus {
  box-shadow: none !important;
}

.ant-select-single .ant-select-selector {
  color: black !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-84xz9s).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  box-shadow: none !important;
}


.ant-table-row {
  cursor: pointer;
}

.ant-table-row .row-antd:hover {
  background: white !important;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  box-shadow: none !important;
  outline: none !important;
}

.ant-input-affix-wrapper:visited {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.ant-table-row span {
  font-size: 13px !important;
}

::file-selector-button {
  display: none;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-form input[type='file']:focus {
  box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-form input[type='file']:focus {
  box-shadow: none !important;
}

/* :where(.css-dev-only-do-not-override-1ih0jvk).ant-steps.ant-steps-label-vertical .ant-steps-item{
  overflow:hidden !important;
} */

.ant-steps-item .ant-steps-item-finish {
  /* width: 60px !important; */
}

::file-selector-button {
  display: none;
}

:where(.css-dev-only-do-not-override-1ih0jvk).ant-steps .ant-steps-item {
  /* flex:1 !important; */
  margin-right: 10px !important;
}

.ant-steps {
  justify-content: start !important;
  margin-right: 80px !important;
  flex-wrap: wrap !important;

}

:where(.css-dev-only-do-not-override-1ih0jvk).ant-steps .ant-steps-item {
  width: 85px !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-etstzf).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  box-shadow: none !important;
}

@media (max-width:600px) {
  .ant-steps {
    display: none !important;
  }
}

.ant-collapse-header {
  height: auto;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #eeb600 !important;
  border-color: #EEB600 !important;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-checkbox .ant-checkbox-input:focus-visible+.ant-checkbox-inner {
  outline: #EEB600 !important;
}

.ant-checkbox .ant-wave-target:hover {
  border: #eeb600 !important;
  outline: #EEB600 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #EEB600 !important;
}

:where(.css-dev-only-do-not-override-etstzf).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-etstzf).ant-input-outlined:focus-within {
  border-color: none !important;
  box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-etstzf).ant-input-outlined:focus {
  border-color: none !important;
}

.border--input:hover {
  border: 1px solid grey !important;
}

.select--All--functionality .ant-select-selector {
  padding: 6px 4px !important;
  box-shadow: none !important;

}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-input-outlined:focus-within {
  border-color: #F9F9FA !important;
  box-shadow: none !important;
}

.custom--input:hover {
  border: 1px solid #F9F9FA !important;
}

/* .ant-switch-inner {
  background-color: #EEB600 !important;
} */

:where(.css-dev-only-do-not-override-2patpx).ant-switch.ant-switch-checked .ant-switch-inner {

  background-color: #EEB600 !important;
  color: #EEB600 !important;
}

/* :where(.css-dev-only-do-not-override-2patpx).ant-switch.ant-switch-unchecked .ant-switch-inner {
  background-color: #EEB600 !important;
  color: #EEB600 !important;
} */

:where(.css-dev-only-do-not-override-2patpx).ant-switch .ant-switch-inner{
  background-color: #FF474D !important;
  color: #FF474D !important;
}

.ant-switch.ant-switch-checked {
  background-color: #EEB600 !important;
  color: #EEB600 !important;
}

.ant-switch{
  background-color: #FF474D !important;
  color: #FF474D !important;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
  background-color: #EEB600 !important;
  color: #EEB600 !important;
}

.ant-switch:hover:not(.ant-switch-disabled) {
  background-color: #FF474D !important;
  color: #FF474D !important;
}

.ant-select-focused:where(.css-etstzf).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
  box-shadow: none !important;
}

:where(.css-1rqnfsa).ant-input-outlined:focus, :where(.css-1rqnfsa).ant-input-outlined:focus-within{
  border-color: none !important;
}


:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-outlined:hover{
  border-color: transparent !important;
}

:where(.css-dev-only-do-not-override-nouobe).ant-picker-outlined:focus, :where(.css-dev-only-do-not-override-nouobe).ant-picker-outlined:focus-within{
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: #EEB600 !important;
}

.ant-image .ant-image-mask{
  border-radius: 0px !important;
}


.ant-image .ant-image-mask:hover{
  border-radius: 0px !important;
}

:where(.css-dev-only-do-not-override-pyniwi).ant-picker-outlined:hover{
  border-color: none !important;
}

:where(.css-dev-only-do-not-override-pyniwi).ant-picker-outlined:focus, :where(.css-dev-only-do-not-override-pyniwi).ant-picker-outlined:focus-within{
    border-color: none !important;
    box-shadow: none !important;
    outline: 0 !important;
}

.ant-picker-focused.ant-picker{
  border-color: none !important;
}

:where(.css-dev-only-do-not-override-pyniwi).ant-picker-outlined{
  border-color: #fff !important;
}

.ant-radio-group-solid{
  margin-right: 20px;
}

.ant-radio-group-solid :where(.css-dev-only-do-not-override-1okl62o).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background-color: #EEB600 !important;
  border: #EEB600 !important;
  color: white !important;
}


:where(.css-dev-only-do-not-override-1okl62o).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
  background-color: #EEB600 !important;
  color: white !important;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-radio-button-wrapper{
  padding-inline: 20px !important;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-radio-button-wrapper:hover{
  color: black !important;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-radio-group{
  display: flex !important;
  align-items: center !important;
}

.header3-text{
  color: rgb(153, 153, 153) !important
}

:where(.css-dev-only-do-not-override-1x6ts5j).ant-btn-primary{
  background-color: #EEB600 !important;
}

#myCheckbox {
  accent-color: #EEB600;
}

.ant-radio-group .ant-radio-group-outline .ant-radio-group-small .ant-picker-calendar-mode-switch label{
  display: none !important;
}

.ant-radio-group-solid{
  margin-right: 4px !important;
}

.ant-popover-inner .ant-popover-inner-content .popover-calendar .ant-picker-calendar .ant-picker-calendar-mini .ant-picker-calendar-header .ant-radio-group .ant-radio-group-outline .ant-radio-group-small .ant-picker-calendar-mode-switch{
  display: none !important;
}

.ant-radio-button-wrapper .ant-radio-button-wrapper-checked{
  display: none !important;
}

.ant-picker-calendar :where(.css-dev-only-do-not-override-1okl62o).ant-radio-group{
  display: none !important;
}

tr td{
  height: 40px !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-4wcnhi).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
  box-shadow: none !important;
  border-color: #EFF2F5 !important;

}

:where(.css-dev-only-do-not-override-1okl62o).ant-picker-outlined:hover{
  border-color: #EFF2F5 !important;
  
}

.ant-picker-outlined{
  border: none !important;
}

:where(.css-dev-only-do-not-override-idprgp).ant-picker-outlined:focus{
  box-shadow: none !important;
  border-color: #EFF2F5 !important;
}



.ant-picker-outlined:focus{
  box-shadow: none !important;
  border-color: #EFF2F5 !important;
}

.ant-picker-outlined:hover{
  border-color: #EFF2F5 !important;
}

.ant-picker-ok :where(.css-1x6ts5j).ant-btn-primary{
  background-color: #EEB600 !important;
}