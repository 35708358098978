@media (min-width: 1200px) and (max-width: 1300px) {
    .resp-text {
        font-size: 12px;
        font-weight: 500;
        position: absolute;
        top: 120px;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .resp-text {
        font-size: 12px;
        font-weight: 500;
        top: 100px;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .resp-text {
        font-size: 12px;
        font-weight: 500;
        top: 85px;
    }
}

@media (min-width: 993px) and (max-width: 1000px) {
    .resp-text {
        font-size: 10px;
        font-weight: 500;
        top: 50px;
    }
}

@media (max-width: 990px) {
    .resp-text {
        display: none !important;
    }

}


/* md:top-16 lg:top-32 md:text-base md:font-semibold lg:text-xl lg:font-bold */